// extracted by mini-css-extract-plugin
export var accordionTitle = "sample-module--accordionTitle--RPPkE";
export var actionContainer = "sample-module--actionContainer--uU1kq";
export var addToButtonContainer = "sample-module--addToButtonContainer--x-osX";
export var attributeContainer = "sample-module--attributeContainer--plCyt";
export var content = "sample-module--content--HhZDS";
export var description = "sample-module--description--VOsQi";
export var details = "sample-module--details--6qIJo";
export var gallery = "sample-module--gallery---RC+Q";
export var heartFillContainer = "sample-module--heartFillContainer--iJWW0";
export var hide = "sample-module--hide--lKz6N";
export var information = "sample-module--information--KatQm";
export var informationContainer = "sample-module--informationContainer--nLPuz";
export var priceContainer = "sample-module--priceContainer--7qtcD";
export var quantityContainer = "sample-module--quantityContainer--X6Zz5";
export var root = "sample-module--root--ey1qf";
export var show = "sample-module--show--Lxf8w";
export var sizeContainer = "sample-module--sizeContainer--NaLjN";
export var suggestionContainer = "sample-module--suggestionContainer--cefH4";
export var vendor = "sample-module--vendor--3l3Hq";
export var wishlistActionContainer = "sample-module--wishlistActionContainer--P3uLv";